import React, {useState} from 'react'
import {Layout} from '../components/Layout'
import {Center, Text, Box, Stack} from '../common'
import {API_URL, MOBILE_NO_REGEX, CASHFREEURL, OERDERID} from '../common/utils'
import axios from 'redaxios'
import {to} from 'await-to-js'
import Card from 'antd/lib/card'
import {Row} from 'antd'
import Column from 'antd/lib/table/Column'
import {useQueryParam, StringParam} from 'use-query-params'

export default function handleresponse() {
  var queryparms = useQueryParam('res', StringParam)
  const params = queryparms[0] != undefined && queryparms[0].split('|')
  console.log('params received____', params)

  const responseData = Object.entries({
    'Transaction Id': params[0],
    'Agreement Number': params[1],
    'Transaction Status': params[2],
    'Transaction Amount': params[3],
    'Transaction Time': params[4]
  })

  React.useEffect(() => {}, [])

  const columnStyle = {
    borderSpacing: '20px 0',
    borderCollapse: 'separate',
    padding: 5,
  }

  return (
    <Layout>
      <Center>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '500px',
            height: '400px',
            backgroundColor: 'rgb(232,232,232)',
          }}
          bordered={true}>
          {params &&
            responseData.map((item, index) => {
              return (
                <tr>
                  <td style={columnStyle}> {item[0]}</td>
                  <td style={columnStyle}> : </td>

                  <td style={columnStyle}>{item[1]}</td>
                </tr>
              )
            })}
        </Card>
      </Center>
    </Layout>
  )
}
